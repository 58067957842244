import store from "@/plugins/vuex/store";

export default {
    hasRole(roleName) {
        return store.getters.getUserRoles.includes(roleName)
    },
    hasRoleBoshqarSaleManager() {
        return this.hasRole('ROLE_BOSHQAR_SALE_MANAGER')
    },
    hasRoleAdmin() {
        return this.hasRole('ROLE_ADMIN')
    },
    hasRoleBoshqarSaleManagerOrAdmin() {
        if (this.hasRoleBoshqarSaleManager) {
            return true;
        }

        return this.hasRoleAdmin;
    },
}