import axios from "./axios"

export default {
    actions: {
        fetchToken(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/users/auth', data)
                    .then((response) => {
                        console.log('Token olindi')
                        console.log(response)

                        context.commit('updateToken', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Token olishda xatolik yuz berdi')
                        reject()
                    })
            })
        },
        fetchUser(context, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/users/' + userId)
                    .then((response) => {
                        console.log('User olindi')
                        console.log(response)

                        context.commit('updateUser', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('User olishda xatolik yuz berdi')
                        reject()
                    })
            })
        },
        refreshToken(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/users/auth/refreshToken', data)
                    .then((response) => {
                        console.log('Token yangilandi')
                        console.log(response)

                        context.commit('updateToken', response.data)
                        resolve()
                    })
                    .catch(() => {
                        context.commit('clearTokens')
                        console.log('Yangi token olishda xatolik yuz berdi')
                        reject()
                    })
            })
        },
        fetchAboutMe(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/users/about_me')
                    .then((response) => {
                        console.log("About me olindi")
                        console.log(response)

                        context.commit('updateUser', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log("About me olishda xatolik yuz berdi")
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateToken(state, tokens) {
            localStorage.setItem('accessToken', tokens.accessToken)
            state.accessToken = tokens.accessToken
            localStorage.setItem('refreshToken', tokens.refreshToken)
            state.refreshToken = tokens.refreshToken
        },
        updateUser(state, user) {
            state.user = user
        },
        clearTokens(state) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            state.accessToken = localStorage.getItem('accessToken')
            state.refreshToken = localStorage.getItem('refreshToken')

            console.log('Token CLEARED')
        }
    },
    state: {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),

        user: {
            id: null,
            email: null,
            roles: [],
            person: null,
            gender: null,
            additionalName: null,
            telephone: null
        }
    },
    getters: {
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        getUser(state) {
            return state.user
        },
        isAuthorized(state) {
            return state.accessToken !== null
        },
        getUserRoles(state) {
            return state.user.roles
        }
    }
}
