import {createRouter, createWebHistory} from "vue-router/dist/vue-router"
import store from "@/plugins/vuex/store"

const ifNotAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next('/')
    } else {
        next()
    }
}

const ifAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next()
    } else {
        next('/login')
    }
}

const routes = [
    {
        path: '/:pageNumber',
        component: () => import('../pages/CustomerListPage.vue'),
        beforeEnter: ifAuthorized
    },
    {
        path: '/customer_location',
        component: () => import('../pages/CustomerLocationPages.vue'),
        beforeEnter: ifAuthorized
    },
    {
        path: '/login',
        component: () => import('../pages/LoginPage.vue'),
        beforeEnter: ifNotAuthorized
    },
    {
        path: '/CustomersFlowPage',
        component: ()=> import('../pages/CustomersFlowPage.vue'),
        beforeEnter: ifAuthorized
    },
    {
        path: '/payment-type',
        component: ()=> import('../pages/PaymentsTypePage.vue'),
        beforeEnter: ifAuthorized
    },
    {
        path: '/customer_connection/:pageNumber',
        component: () => import('../pages/CustomerContactPage.vue'),
        beforeEnter: ifAuthorized
    }
]

export default createRouter({
    history: createWebHistory(),
    routes
})
