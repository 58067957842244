<template>
    <SidebarComponent v-if="$route.path !== '/login' && $route.path !== '/'"/>
    <div :class="{'main_content': $route.path !== '/login'}">
        <router-view/>
    </div>
</template>

<script>

import SidebarComponent from "@/components/SidebarComponent.vue";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixins/accessMixin";

export default {
    name: 'App',
    components: {SidebarComponent},
    mixins: [accessMixin],
    computed: {
        ...mapGetters(['isAuthorized', 'getRefreshToken'])
    },
    methods: {
        ...mapActions(['fetchAboutMe', 'refreshToken'])
    },
    mounted() {
        if (this.isAuthorized) {
            this.fetchAboutMe()
                .then(() => {
                    if (this.hasRoleBoshqarSaleManager && !(/^\/customer_connection\/[0-9]*/).test(this.$route.path)) {
                        this.$router.push('/customer_connection/1')
                    }
                })
                .catch(() => {
                    this.refreshToken({refreshToken: this.getRefreshToken})
                        .then(() => {
                            this.$router.push('/')
                            this.fetchAboutMe()
                        })
                        .catch(() => {
                            this.$router.push('/login')
                        })
                })
        } else {
            this.$router.push('/login')
        }
    },
    watch: {
        '$route'() {
            if ((/^\/$/).test(this.$route.path)) {
                this.$router.push('/1')
            }
            if ((/^\/customer_connection\/?$/).test(this.$route.path)) {
                this.$router.push('/customer_connection/1')
            }
        },
    },
}
</script>

<style>
* {
    color: #000;
}

a {
    color: inherit;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.main_content {
    margin-left: 260px;
}

@media (max-width: 991px) {
    .main_content {
        margin-left: 0;
    }
}
</style>
