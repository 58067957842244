<template>
    <!--  LOGOUT CONFIRM MODAL START -->
    <div id="confirmLogOutModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 id="exampleModalLabel" class="modal-title fs-5">Chiqishni tasdiqlang</h1>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Bekor qilish</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" type="button" @click="logOut">
                        Chiqish
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--  LOGOUT CONFIRM MODAL END -->

    <svg
        @click="showSidebar"
        class="menu-btn d-lg-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
    >
        <path
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
    </svg>
    <div @click.stop="hideSidebar" class="sidebar" :class="{'sidebar-shadow_active': isShadowActive}">
        <div
            @click.stop
            class="sidebar__content d-flex flex-column justify-content-between"
            :class="{'sidebar_active': isSidebarActive, 'sidebar_deactive': !isSidebarActive}"
        >
            <div>
                <svg
                    class="close-btn d-lg-none"
                    @click.stop="hideSidebar"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.403307 2.34932C-0.128688 1.81221 -0.128688 0.939941 0.403307 0.402832C0.935301 -0.134277 1.79926 -0.134277 2.33125 0.402832L6.81277 4.93174L11.2985 0.407129C11.8305 -0.129981 12.6945 -0.129981 13.2265 0.407129C13.7585 0.944238 13.7585 1.8165 13.2265 2.35361L8.74072 6.87822L13.2222 11.4071C13.7542 11.9442 13.7542 12.8165 13.2222 13.3536C12.6902 13.8907 11.8263 13.8907 11.2943 13.3536L6.81277 8.82471L2.327 13.3493C1.795 13.8864 0.931045 13.8864 0.399051 13.3493C-0.132944 12.8122 -0.132944 11.9399 0.399051 11.4028L4.88483 6.87822L0.403307 2.34932Z"
                        fill="white"/>
                </svg>
                <div class="sidebar__title">Admin</div>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item" style="background-color: #6f42c1">
                        <h2 class="accordion-header">
                            <button
                                class="accordion-button collapsed main"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                                style="background-color: #6f42c1"
                            >
                                Asosiy
                            </button>
                            <div class="main__underline"></div>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse show"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body">
                                <ul>
                                    <router-link v-if="hasRoleAdmin" to="/1">
                                        <li
                                            class="main__item"
                                            :class="{'router-link-exact-active': (/^\/[0-9]+$/).test(this.$route.path)}"
                                            @click="hideSidebar"
                                        >
                                            Barcha mijozlar
                                        </li>
                                    </router-link>
                                    <router-link v-if="hasRoleAdmin" to="/customer_location">
                                        <li
                                            class="main__item"
                                            @click="hideSidebar"
                                        >
                                            Mijozlar joylashuvi va to'lov
                                        </li>
                                    </router-link>
                                    <router-link v-if="hasRoleAdmin" to="/CustomersFlowPage">
                                        <li
                                            class="main__item"
                                            @click="hideSidebar"
                                        >
                                            Mijozlar oqimi
                                        </li>
                                    </router-link>
                                    <router-link v-if="hasRoleBoshqarSaleManagerOrAdmin" to="/customer_connection/1">
                                        <li
                                            class="main__item"
                                            :class="{'router-link-exact-active': (/^\/customer_connection\/[0-9]+$/).test(this.$route.path)}"
                                            @click="hideSidebar"
                                        >
                                            Mijoz bilan bog'lanish
                                        </li>
                                    </router-link>
                                    <router-link v-if="hasRoleAdmin" to="/payment-type">
                                        <li
                                            class="main__item"
                                            @click="hideSidebar"
                                        >
                                            To'lov turi
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end text-uppercase">
                <span
                    class="fw-bold px-3 text-light cursor-pointer"
                    data-bs-target="#confirmLogOutModal"
                    data-bs-toggle="modal"
                >Chiqish</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import accessMixin from "@/mixins/accessMixin";

export default {
    name: "SidebarComponent",
    data() {
        return {
            isShadowActive: false,
            isSidebarActive: false
        }
    },
    mixins: [accessMixin],
    methods: {
        ...mapMutations(['clearTokens']),
        showSidebar() {
            this.isShadowActive = true
            this.isSidebarActive = true
            document.body.classList.add('overflow-hidden')
        },
        hideSidebar() {
            this.isShadowActive = false
            this.isSidebarActive = false
            document.body.classList.remove('overflow-hidden')
        },
        logOut() {
            this.$router.push('/login')
            this.clearTokens()
        }
    },
}
</script>

<style scoped>
ul, li {
    display: block;
    margin: 0;
    padding: 0;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    z-index: 49;
    display: flex;
}

.sidebar-shadow_active {
    width: 100%;
}

.sidebar__content {
    padding: 40px 20px;
    background-color: #6f42c1;
    width: 260px;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    z-index: 50;
}

.sidebar__title {
    font-weight: 700;
    font-size: 25px;
    color: #fff;
    user-select: none;
}

.menu-btn {
    position: absolute;
    top: 10px;
    width: 45px;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 0 14px;
    cursor: pointer;
    background: #e2e6ea;
    opacity: 0.8;
}

.menu-btn:hover {
    background: rgba(216, 220, 224, 0.85);
    opacity: 1;
}

.close-btn {
    width: 24px;
    position: absolute;
    top: 14px;
    right: 14px;
    padding: 6px;
    cursor: pointer;
    user-select: none;
}

.accordion-body {
    padding-right: 8px;
}

.accordion-button {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.main {
    display: block;
    text-transform: uppercase;
}

.main__underline::after {
    display: block;
    content: "";
    background-color: rgba(229, 229, 229, 0.22);
    width: 100%;
    height: 1px;
    margin-top: 3px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #edc661;
    background-color: #edc661;
    box-shadow: none;
}

.main__item {
    margin-bottom: 10px;
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
}

.main__item:hover {
    color: #edc661;
}

a.router-link-exact-active li, .router-link-exact-active {
    color: #edc661;
}

.main__item::after {
    display: block;
    content: "";
    background-color: rgba(229, 229, 229, 0.22);
    width: 100%;
    height: 1px;
    margin-top: 7px;
}

.btn-primary {
    background: #6f42c1;
    border: none;
}

@media (max-width: 991px) {
    .sidebar__content {
        left: -260px;
        opacity: 0.8;
    }

    .sidebar_active {
        left: 0;
        opacity: 1;
        transition: 0.3s;
    }

    .sidebar_deactive {
        left: -260px;
        opacity: 0.8;
        transition: 0.3s;
    }
}
</style>
