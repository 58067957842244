import axios from "./axios"

export default {
    actions: {
        pushContactWithCompany(context, data) {
            return new Promise((resolve, reject) => {
                axios.post('/contact_with_companies', data)
                    .then((response) => {
                        console.log('Kompaniya ma\'lumotlari yuborildi')
                        console.log(response)

                        context.commit('updateContactWithCompany', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Kompaniya ma\'lumotlarini yuborishda xatolik')
                        reject()
                    })
            })
        },
        fetchContactWithCompany(context, companyId) {
            return new Promise((resolve, reject) => {
                axios.get('/contact_with_companies?company=' + companyId)
                    .then((response) => {
                        console.log('Kompaniya ma\'lumotlari olindi')
                        console.log(response)

                        let contactWithCompany  = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }

                        context.commit('fetchDataContactWithCompany', contactWithCompany)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Kompaniya ma\'lumotlarini olishda xatolik')
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateContactWithCompany(state, data) {
            state.data = data
        },
        fetchDataContactWithCompany(state, contactWithCompany) {
            state.contactWithCompany = contactWithCompany
        }
    },
    state: {
        contactWithCompany: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getContactWithCompany(state) {
            return state.contactWithCompany.models
        }
    }
}
