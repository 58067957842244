import axios from "./axios"

export default {
    actions: {
        fetchFilteredCompanies(context, filterProps = {}) {
            let companyUrl = '';

            if (filterProps.fullName) {
                companyUrl += '&full-name=' + filterProps.fullName;
            }

            if (filterProps.phoneNumber) {
                companyUrl += '&phone-number=' + filterProps.phoneNumber;
            }

            if (filterProps.email) {
                companyUrl += '&email=' + filterProps.email;
            }

            if (filterProps.purchaseCount) {
                companyUrl += '&purchase-count=' + filterProps.purchaseCount;
            }

            if (filterProps.expression) {
                companyUrl += '&expression=' + filterProps.expression;
            }

            if (filterProps.planTypeId) {
                companyUrl += '&plan-type-id=' + filterProps.planTypeId;
            }

            if (filterProps.isActive) {
                companyUrl += '&is-active=' + filterProps.isActive;
            }

            if (filterProps.periodFrom) {
                companyUrl += '&from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                companyUrl += '&to=' + filterProps.periodTo;
            }

            if (filterProps.signUpDateOrderByAsc) {
                companyUrl += '&sign-up-order=' + filterProps.signUpDateOrderByAsc;
            }

            if (filterProps.expiredDateOrderByAsc) {
                companyUrl += '&expired-order=' + filterProps.expiredDateOrderByAsc;
            }

            return new Promise((resolve, reject) => {
                axios
                    .get('/all_company?page=' + filterProps.currentPage + companyUrl)
                    .then((response) => {
                        console.log('Companies list SUCCESSFULLY received')
                        console.log(response)

                        let company = {
                            models: response.data.filteredCompanies['hydra:member'],
                            itemsCount: {
                                filteredCompaniesCount: response.data.filteredCompaniesCount,
                                itemsPerPage: response.data.itemsPerPage
                            }
                        }

                        context.commit('updateCompany', company)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Getting companies list FAILED')
                        reject()
                    })
            })
        },
        fetchCardIndicators(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/card_indicators')
                    .then((response) => {
                        console.log('Сard indicators received SUCCESSFULLY')

                        context.commit('updateCardIndicators', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Getting card indicators FAILED')
                        reject()
                    })
            })
        },
        fetchCompaniesForExport(context, filterProps = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/export_companies?from=' + filterProps.periodFrom + '&to=' + filterProps.periodTo)
                    .then((response) => {
                        console.log(response)
                        console.log('Companies for EXPORT SUCCESSFULLY received')

                        let company = {
                            models: response.data.filteredCompanies['hydra:member']
                        }
                        context.commit('updateCompany', company)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Getting companies list FAILED')
                        reject()
                    })
            })
        },
        fetchCompaniesForExportDataCount(context, filterProps = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/export_companies_count?from=' + filterProps.periodFrom + '&to=' + filterProps.periodTo)
                    .then((response) => {
                        console.log('Companies COUNT for export SUCCESSFULLY received')

                        context.commit('updateCompanyCount', response.data.count)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Getting companies list FAILED')
                        reject()
                    })
            })
        },


    },
    mutations: {
        updateCompany(state, company) {
            state.company = company
        },
        updateCardIndicators(state, cardIndicators) {
            state.cardIndicators = cardIndicators
        },
        updateCompanyCount(state, companyCount) {
            state.companyCount = companyCount
        }
    },
    state: {
        company: {
            models: [],
            itemsCount: {
                filteredCompaniesCount: 0,
                itemsPerPage: 0,
            },
        },
        cardIndicators: {
            allCompaniesCount: 0,
            usedMoreThanAYearCount: 0,
            paidOnceCount: 0,
            stoppedUsingCount: 0,
            signUpProgressPercent: 0,
            usedMoreThanAYearProgressPercent: 0,
            paidOnceProgressPercent: 0,
            stoppedUsingProgressPercent: 0
        },
        companyCount: 0
    },
    getters: {
        getCompany(state) {
            return state.company.models
        },
        getCompanyItemsCount(state) {
            return state.company.itemsCount
        },
        getCardIndicators(state) {
            return state.cardIndicators
        },
        getCompanyExportItemsCount(state) {
            return state.companyCount
        },
    }
}
