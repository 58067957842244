import axios from "./axios"

export default {
    actions: {
        fetchAllYearCustomerFlow(context) {
            return new Promise((resolve, reject) => {
                axios.get('/browser/users/year')
                    .then((response) => {
                        console.log('AllYearCustomerFlow received')
                        console.log(response)

                        context.commit('updateAllYearCustomerFlow', response.data['hydra:member'])
                        resolve()
                    })
                    .catch(() => {
                        console.log('AllYearCustomerFlow FAILED')
                        reject()
                    })
            })
        },
        fetchAllMonthCustomerFlow(context, selectedMonth) {
            let monthUrl = '';

            if (selectedMonth) {
                monthUrl = '?month=' + selectedMonth;
            }

            return new Promise((resolve, reject) => {
                axios.get('/browser/users/month' + monthUrl)
                    .then((response) => {
                        console.log('AllMonthCustomerFlow received')
                        console.log(response)

                        context.commit('updateAllMonthCustomerFlow', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('AllMonthCustomerFlow FAILED')
                        reject()
                    })
            })
        },
        fetchUsersByBrowser(context) {
            return new Promise((resolve, reject) => {
                axios.get('/browser/users')
                    .then((response) => {
                        console.log('Users browser received')
                        console.log(response)

                        let users  = {
                            mobileBrowser: response.data['hydra:member'][0],
                            desktopBrowser: response.data['hydra:member'][1]
                        }

                        context.commit('updateUsersByBrowser', users)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Users browser FAILED')
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateAllYearCustomerFlow(state, customers) {
            state.customers = customers
        },
        updateAllMonthCustomerFlow(state, customer) {
            state.customer = customer
        },
        updateUsersByBrowser(state, users) {
            state.mobileBrowser = users.mobileBrowser
            state.desktopBrowser = users.desktopBrowser
        },
    },
    state: {
        customers: [],

        customer: {
            month: '',
            customerCount: 0,
            customerPercent: 0
        },
        mobileBrowser: {
            browsers: [],
            allUsersCount: 0
        },
        desktopBrowser: {
            browsers: [],
            allUsersCount: 0
        },
    },
    getters: {
        getAllYearCustomerFlow(state) {
            return state.customers
        },
        getAllMonthCustomerFlow(state) {
            return state.customer
        },
        getMobileBrowser(state) {
            return state.mobileBrowser
        },
        getDesktopBrowser(state) {
            return state.desktopBrowser
        },
    }
}
