import axios from "./axios"

export default {
    actions: {
        fetchUsersByLocation(context) {
            return new Promise((resolve, reject) => {
                axios.get('/location/users')
                    .then((response) => {
                        console.log('Users location received')
                        console.log(response)

                        context.commit('updateUsersByLocation', response.data['hydra:member'])
                        resolve()
                    })
                    .catch(() => {
                        console.log('Users location FAILED')
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateUsersByLocation(state, locations) {
            state.locations = locations
        }
    },
    state: {
        locations: []
    },
    getters: {
        getLocations(state) {
            return state.locations
        }
    }
}
