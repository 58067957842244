import {createStore} from "vuex";
import user from "@/plugins/vuex/user";
import company from "@/plugins/vuex/company";
import contactWithCompany from "@/plugins/vuex/contactWithCompany";
import location from "@/plugins/vuex/location";
import planPurchase from "@/plugins/vuex/planPurchase";
import browser from "@/plugins/vuex/browser";
export default createStore({
    modules: {
        browser,
        user,
        company,
        contactWithCompany,
        location,
        planPurchase
    }
})
