import axios from "./axios"

export default {
    actions: {
        fetchPlanAndPayments(context, selectedYear) {
            let yearUrl = ''

            if (selectedYear) {
                yearUrl = '?year=' + selectedYear
            }

            return new Promise((resolve, reject) => {
                axios.get('/plan_and_payments' + yearUrl)
                    .then((response) => {
                        console.log('reja va to\'lovlar olindi')
                        console.log(response)

                        context.commit('updatePlanAndPayments', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('reja va to\'lovlar olishda xatolik')
                        reject()
                    })
            })
        },
        fetchAllYearPayments(context) {
            return new Promise((resolve, reject) => {
                axios.get('/all_year_payments')
                    .then((response) => {
                        console.log('all_year_payments received')
                        console.log(response)

                        context.commit('updateAllYearPayments', response.data['hydra:member'])
                        resolve()
                    })
                    .catch(() => {
                        console.log('all_year_payments FAILED')
                        reject()
                    })
            })
        },
        fetchAllMonthPayments(context, selectedMonth) {
            let monthUrl = '';

            if (selectedMonth) {
                monthUrl = '?month=' + selectedMonth;
            }

            return new Promise((resolve, reject) => {
                axios.get('/all_month_payments' + monthUrl)
                    .then((response) => {
                        console.log('all_month_payments received')
                        console.log(response)

                        context.commit('updateAllMonthPayments', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('all_month_payments FAILED')
                        reject()
                    })
            })
        },
        fetchPlanTypes(context, selectedMonth) {
            let monthUrl = '';

            if (selectedMonth) {
                monthUrl = '?month=' + selectedMonth;
            }

            return new Promise((resolve, reject) => {
                axios.get('/customer_plan_types' + monthUrl)
                    .then((response) => {
                        console.log('PlanTypes received')
                        console.log(response)

                        let planTypes  = {
                            planTypeCount: response.data['hydra:member'][0],
                            planTypePercent: response.data['hydra:member'][1]
                        }

                        context.commit('updatePlanTypes', planTypes)
                        resolve()
                    })
                    .catch(() => {
                        console.log('PlanTypes FAILED')
                        reject()
                    })
            })
        },
    },
    mutations: {
        updatePlanAndPayments(state, data) {
            state.payments = data.payments['hydra:member']
            state.planAndPayments = data
        },
        updateAllYearPayments(state, allYearPayments) {
            state.allYearPayments = allYearPayments
        },
        updateAllMonthPayments(state, allMonthPayments) {
            state.allMonthPayments = allMonthPayments
        },
        updatePlanTypes(state, planTypes) {
            state.planTypeCount = planTypes.planTypeCount
            state.planTypePercent = planTypes.planTypePercent
        },
    },
    state: {
        payments: [],
        allYearPayments: [],

        planAndPayments: {
            paymentSum: 0,
            pendingPaymentSum: 0,
            paymentPercent: 0,
            pendingPaymentPercent: 0
        },

        allMonthPayments: {
            month: '',
            monthlyPlanTypePayed: 0,
            yearlyPlanTypePayed: 0
        },

        planTypeCount: {
            lite: 0,
            standart: 0,
            premium: 0
        },

        planTypePercent: {
            lite: 0,
            standart: 0,
            premium: 0
        },
    },
    getters: {
        getPayments(state) {
            return state.payments
        },
        getPlanAndPayments(state) {
            return state.planAndPayments
        },
        getAllYearPayments(state) {
            return state.allYearPayments
        },
        getAllMonthPayments(state) {
            return state.allMonthPayments
        },
        getPlanTypeCount(state) {
            return state.planTypeCount
        },
        getPlanTypePercent(state) {
            return state.planTypePercent
        },
    }
}
